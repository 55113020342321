body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* leaflet */
.leaflet-popup-tip-container {
  visibility: hidden;
}

.leaflet-popup-content-wrapper {
  text-align: center;
}

.leaflet-popup-content {
  margin: 4px 19px;
  line-height: 1.2;
  overflow-wrap: anywhere;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
}

.leaflet-popup-xxl .leaflet-popup-content {
  margin: 8px 19px;
  line-height: 1.4;
}

/* react-perfect-scrollbar */
.always-visible-perfect-scrollbar > .ps__rail-x,
.always-visible-perfect-scrollbar > .ps__rail-y {
  opacity: 0.6;
}

/* datepickers icon button outline  */
*:focus {
  outline: none !important;
}
